import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { useState } from 'react';

import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';

import ReactGA from 'react-ga4';
import Minigames from './games/Minigames';
import Moregames from './games/Moregames';
import Badges from '../library/Badges';
import Comments from './Comments';
import '../static/css/tilesets.css';
import NewAd from './NewAd';
import GoogleAd from './GoogleAd';

import levels_lines from '../static/json/levels_lines.json';
import levels_shuzzle from '../static/json/levels_shuzzle.json';
import { getStars } from '../library/stars.js';
import { getLevels } from '../library/levels.js';

import badgesJson from '../static/json/badges.json';

function bigName(gameName) {
		const names = {
        	"calendar": "ADVENT CALENDAR",
        	"lines": "COLOR LINES",
        	"same": "SAME GAME",
        	"epicshuzzle": "EPIC SHUZZLE",
        	"multishuzzle": "MULTI SHUZZLE"
        };
        if(gameName in names) {
        	return names[gameName];
        } else {
			return gameName.toUpperCase();
		}
}



function Tileset({game, tileset, stars, date}) {

	const navigate = useNavigate();
	const { t } = useTranslation();
	const detectedLanguage = window.location.pathname.split('/')[1];


	function bestScore() {
		var best = localStorage.getItem(game + "-" + tileset + "-best");

		if(best != undefined) {
			return (<>{t("bestSoFar")}: {best}</>)
		} else {
			return (<>  </>)
		}
	}

	function getUrl({game, tileset}) {
		return ("/" + detectedLanguage + "/" + game + "/" + tileset);
	}

	function playGame({game, tileset}) {
	    ReactGA.event({
	        category: 'Game',
	        action: 'StartGame'
	    });
	    window.location.href = getUrl(game, tileset);
    }

	function playTileset({available, game, tileset}) {
		if(available) {
			ReactGA.event({
		        category: 'Game',
		        action: 'StartGame'
		    });
		    window.location.href = getUrl({game, tileset});
		}
    }

	function textToUpper({game, tileset}) {
		const titles = {
        	"rubik": "RUBIK HARD",
        	"rubik3": "RUBIK EASY",
        	"bwcircles": "B&W CIRCLES",
        	"nightgarden": "NIGHT GARDEN",
        	"blacksquares": "BLACK SQUARES",
        	"whitelines": "WHITE LINES",
        	"mondrian": "PIET MONDRIAN",
        	"triumphalarch": "ARCH"
        };
        if(game == "sparkit" && tileset.includes("basic")) {
            tileset = tileset.replace("basic", "basic-");
        }
        if(tileset in titles) {
        	return titles[tileset];
        } else {
			return tileset.toUpperCase();
		}
	}

	function getImage(available, game, tileset) {
		if(game === "lines") {

			if(available) {
		        return(<Card.Img variant="top" className="squareTilesetImage" src={"/images/games/" + game + "/" + tileset + ".png"}  /> );
		    } else {
		        return(<Card.Img variant="top" className="squareTilesetImage newTileset" src={"/images/games/" + game + "/" + tileset + ".png"}  /> );
	        }
		}
		if(game == "calendar" && stars == 0) {
			if(available) {
				return (<Card.Img alt={"calendar " + tileset} variant="top" src={"/images/games/calendar/" + tileset + "_closed.png"}  />);
			} else {
				return (<Card.Img alt={"calendar " + tileset} className="newTileset" variant="top" src={"/images/games/calendar/" + tileset + "_closed.png"}  />);
			}
		}

		var coverTileset = (<></>);
		if(game == "timed" || game == "infinite" || game == "pairs") {
			coverTileset = (<Card.Img style={{"position": "absolute"}} variant="top" src={"/images/games/" + game + "/cover.png"}  />);
		}
		if(game == "unpaired" || game == "timed" || game == "infinite"|| game == "pairs" ) {game="tiles";}
		if(available) {
	        return (<> {coverTileset}
	        <Card.Img alt={game + " game " + tileset } variant="top" src={"/images/games/" + game + "/" + tileset + ".png?v=new"}  /></> );

	    } else {
	        return (<Card.Img className="newTileset" variant="top" src={"/images/games/" + game + "/" + tileset + ".png"}  />);
        }
	}

	function getStarsCode() {
		if(game == "multishuzzle") {
			return (
			 <h5 className="tilesetStars">
			    <img className="levelStar" src={"/images/tilesets/lines/star.png"}/> {stars} / 27
			 </h5>);
		}

		return (
			<div className="tilesetStars">
				{(stars >= 1) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
				: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}
				{(stars >= 2) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
				: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}
				{(stars >= 3) ? <img className="levelStar" src={"/images/tilesets/lines/star.png"}/>
				: <img className="levelStar greyStar" src={"/images/tilesets/lines/star.png"}/>}

			</div>
		);
	}


	var available = false, availableButHidden = false;

	if(date === "") {
		available = true;
	} else {
		var available = false;
        const todayDate = new Date();
        const dateDate = new Date(date);
		dateDate.setHours(dateDate.getHours() - 1);
		if(dateDate <= todayDate) {
			available = true;
		}
	}

	if(game == "calendar" && ["25", "26", "27", "28", "29","30","31"].includes(tileset) && available) {
		available = false;
		var openWindows = 0;
		for(var i = 1; i <= 24; i++) {
			if(getStars((i).toString(), game) > 0 ) {
				openWindows++;
			}
		}
		if(openWindows >= 24) {
			available = true;
		} else {
			availableButHidden = true;
		}
	}

	var color = "white";
	const fourLayersSets = getLevels("fourlayers");
	if(game == "tiles" && fourLayersSets.includes(tileset)) {
		color = "#ccdec4";
	}

    return (
        (<Col className='tilesetContainer'>
            <Card style={{"background-color": color}} onClick={() => playTileset({available, game, tileset})}
                className={["swapoku","shuzzle","epicshuzzle","multishuzzle","pixit","calendar","lines"].includes(game) ? "tilesetCard bigTilesetCard" : "tilesetCard"}>
                {
                    getImage(available, game, tileset)
                }

                <Card.Body>
                    {
                        game == "swapoku"  || game == "pixit" || game == "shuzzle" || game == "epicshuzzle" || game == "multishuzzle" || game == "calendar"
                        ? <Card.Title>{date}</Card.Title>
                        : game == "lines"
                            ?   <Card.Title className="tilesetName">{textToUpper({game, tileset})}</Card.Title>
                            :   <><Card.Title className="tilesetName">{textToUpper({game, tileset})}</Card.Title><br/>
	                            <Card.Text className="tilesetDate">{date}</Card.Text></>
                    }
                    { getStarsCode() }
                    {
                    game == "multishuzzle"
                    ? <></>
                    : <Card.Text>{bestScore()}</Card.Text>
                    }
                    {
                        available
                        ? <Button className="small-btn" variant="primary"
                            onClick={() => playGame({game, tileset})}>{t("play")}</Button>
                        : availableButHidden
                            ? <>Open all 24 windows to unlock this level </>
                            : <p className="levelNotAvailable">Level available from<br/> {date} </p>
					}
                </Card.Body>
            </Card>
        </Col>)

    );
}

function getTilesets(game) {

	var ts, tilesets = [];
	const tilesgames = ["tiles", "tilesbig", "pairs", "infinite", "timed", "unpaired"];
	var listName = tilesgames.includes(game) ? "waiting" : game;

	const todayDate = new Date();
	const tomorrowDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

	var levels = getLevels(listName);
	for(var i = 0; i < levels.length; i++) {
		ts = levels[i];
		var dateDate = new Date(ts["date"]);
		dateDate.setHours(dateDate.getHours() - 1);
		if(dateDate <= tomorrowDate) {
            tilesets.push(ts);
        }
	}
	return tilesets;
}

function getTilesetsAvailable(game) {

	var ts, tilesets = [];
	const tilesgames = ["tiles", "tilesbig", "pairs", "infinite", "timed", "unpaired"];
	var listName = tilesgames.includes(game) ? "waiting" : game;

	const todayDate = new Date();
	var dateDate = new Date();
	dateDate.setHours(dateDate.getHours() - 1);

	var levels = getLevels(listName);
	for(var i = 0; i < levels.length; i++) {
		ts = levels[i];
		dateDate = new Date(ts["date"]);
		dateDate.setHours(dateDate.getHours() - 1);

		if(dateDate <= todayDate) {
            tilesets.push(ts);
        }
	}
	return tilesets;

}

function goToGame({game}) {
	ReactGA.event({
	    category: 'Game',
	    action: 'ChangeTilesets',
	    label: game
	});
	window.location.href = "/en/" + game + "/tilesets";
}

function Icon({game, current}) {

    const todayDate = new Date();
    const shuzzleDate = new Date("2025-01-01");
    shuzzleDate.setHours(shuzzleDate.getHours() - 1);

	var addClass = "";
	if(game != current) {
		addClass = " hiddenIcon"
	}
	var gameName = game[0].toUpperCase() + game.substring(1);
	if(gameName == "Epicshuzzle") {
		gameName = "Epic shuzzle";
	}

	return (
		<div className={"gameIconBox" + addClass} >

			<img onClick={() => goToGame({game})} className={"gameIcon" + addClass} alt={game} variant="top" src={"/images/games/" + game + "/default.png"}  />
			<span>{gameName}</span>

		</div>
	);
}

function Snow() {
	const n = 15;
	return (<>
	{
		[...Array(n)].map((e, i) => <div className="snowflake">❅</div>)
	}
	</>);
}

function splitTilesets(tilesets, game) {

	var months = [];
	for(var i = 0; i < tilesets.length; i++) {
		if(tilesets[i]["date"] != undefined) {
			var m = (tilesets[i]["date"]).substring(0,7);
			if(["calendar", "segments", "sparkit", "same", "lines", "neighbours", "shuzzle", "epicshuzzle", "multishuzzle", "pixit"].includes(game)) {
				m = tilesets[i]["part"];
			}

			if(months.length == 0 || months[months.length-1]["month"] != m) {
				months.push({
					"month": m,
					"tilesets": [tilesets[i]],
					"stars": getStars(tilesets[i]["name"], game),
					"availableStars": 0,
					"cover": "",
					"newLevels": 0
				});
				const todayDate = new Date();
				var dateDate = new Date(tilesets[i]["date"]);
				dateDate.setHours(dateDate.getHours() - 1);
				if(dateDate <= todayDate) {
			        //months[months.length-1]["availableStars"] += 3;
			        if(game == "multishuzzle") {
						months[months.length-1]["availableStars"] += 27;
					} else {
						months[months.length-1]["availableStars"] += 3;
					}

			        months[months.length-1]["cover"] = tilesets[i]["name"];
			    } else {
			        months[months.length-1]["newLevels"] = 1;
			    }


			} else {
				months[months.length-1]["tilesets"].push(tilesets[i]);
				months[months.length-1]["stars"] += getStars(tilesets[i]["name"], game);
				if(game == "multishuzzle") {
					months[months.length-1]["availableStars"] += 27;
				} else {
					months[months.length-1]["availableStars"] += 3;
				}
				if(months[months.length-1]["cover"] == "") {
                    months[months.length-1]["cover"] = tilesets[i]["name"];
                }
			}

		}
	}
	return months;
}

function getShowLevelsOption() {
	var showLevelsOption = localStorage.getItem("showLevelsOption");
	if(showLevelsOption != "A" && showLevelsOption != "B") {
		return "A";
	} else {
		return showLevelsOption;
	}
}

const Tilesets = ({game, language, last=false}) => {
	const { t } = useTranslation();
	var tilesets = getTilesets(game);
	var tilesetsAvailable = getTilesetsAvailable(game);

	while(tilesetsAvailable.length + 1 < tilesets.length) {
		tilesets.shift();
	}

	const [scrollPosition, setScrollPosition] = useState(last ? 0 : 480);
	React.useEffect(() => {
        window.scrollTo(0, scrollPosition);
    }, []);

    const [months, setMonths] = useState(splitTilesets(tilesets, game));


	tilesets = tilesets.sort((a, b) => {
        if(a["date"] != b["date"]) {
            return new Date(b["date"]) - new Date(a["date"]); // Sort in ascending order
        } else {
            return b["name"] - a["name"];
        }
    });

	var tilesetsNotFinished = tilesetsAvailable.sort((a, b) => {
		const starsA = getStars(a["name"], game);
		const starsB = getStars(b["name"], game);
        return starsA - starsB;
    }).filter((a) => {
        const starsA = getStars(a["name"], game);
        return starsA != 4;
    });


    var w = window.innerWidth;
	var numberOfLevelsInRow = 2;
	if(w >= 768) {
		numberOfLevelsInRow = 3;
	}
	const [firstLevel, setFirstLevel] = useState(0);



	var shownTileset = (months.length == 1) ? 0 : -1;
	const [shown, setShown] = useState(shownTileset);

	const [linkCopied, setLinkCopied] = useState(false);


	const [selectedOption, setSelectedOption] = useState(getShowLevelsOption());
    const toggleOption = () => {
        var newOption =  (selectedOption === 'A' ? 'B' : 'A');
        setSelectedOption(newOption);
        localStorage.setItem("showLevelsOption", newOption);
        ReactGA.event({
            category: 'Game',
        	action: 'ShowLevelsOption',
        	label: newOption
        });
    };



	let detectedLanguage = window.location.pathname.split('/')[1];
	if(detectedLanguage === "") {
		detectedLanguage = "en";
	}

	function toggleVisibility(id) {
	    setShown(id);
	    setScrollPosition(750);
	    window.scrollTo(0, 750);

	    return true;
	}

	function monthToTitle(month, game) {
		if(["calendar", "segments", "sparkit", "same", "lines", "neighbours", "shuzzle", "epicshuzzle","multishuzzle", "pixit"].includes(game)) {
			return month;
		}
		var date = month.split("-");
		const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
		return monthNames[Number(date[1])-1] + " " + date[0];
	}

	function gameToUpper({game}) {
		const titles = {
			"tiles": "TILES",
        	"infinite": "INFINITE TILES",
        	"same": "SAME GAME",
        };
        if(game in titles) {
        	return titles[game];
        } else {
			return game.toUpperCase()
		}
	}


	function getStarsTileset({tileset, game}) {
		tileset = tileset["name"];
		return getStars(tileset, game);
	}

	function countStars(game) {
		var stars = 0;
		var tsets = getTilesets(game);

		for(var i = 0; i < tsets.length; i++) {
			var tset = tsets[i];
			var tileset = tset["name"];
			stars += getStars(tileset, game);

		}
		return stars;
	}


	function getInstruction(game) {
		var inst = {
			"tiles": "",
			//"tiles": "Look for tiles that have a common element. Score as many points as possible.",
			"pairs": "Find pairs of identical tiles.",
			"infinite": "Infinite version - tiles recreate when cleared.",
			"lines": "Draw the goal image in as few moves as possible.",
			"same": "Clear the board in as few moves as possible.",
			"unpaired": "Find the square that doesn't have a pair!",
			"timed": "You have 100 seconds to create the longest combo."
		}
		return inst[game];
	}

//	function copyLink({game, setLinkCopied}) {
//		ReactGA.event({
//	        category: 'Game',
//	        action: 'ShareResult'
//	    });
//	    setLinkCopied(true);
//	    var link = "https://www.unlimited-tiles.com/en/" + game;
//	    var textToShare = "Hi! My score in " + bigName(game) + " is ⭐ " + countStars(game) + "/" + (3*tilesetsAvailable.length) + ". Can you beat me?";
//
//	    navigator.clipboard.writeText(textToShare + "\n\n" + link);
//	}

	function prevLevels() {
		var nextFirstLevel = firstLevel - numberOfLevelsInRow;
		if(nextFirstLevel < 0) {
			nextFirstLevel = tilesetsNotFinished.length - numberOfLevelsInRow;
		}
		setFirstLevel(nextFirstLevel);
	}

	function nextLevels() {
		var nextFirstLevel = firstLevel + numberOfLevelsInRow;
		if(nextFirstLevel > tilesetsNotFinished.length - numberOfLevelsInRow) {
			nextFirstLevel = 0;
		}
		setFirstLevel(nextFirstLevel);
	}


	const allgames =  [ "epicshuzzle", "shuzzle", "pixit", "calendar","sparkit", "segments",  "swapoku", "neighbours", "pairs",  "same", "timed", "infinite", "lines", "unpaired", "tiles"];

	const tilesetHtml = (
        <div>

	        <Helmet>
	            <title>Unlimited Tiles - {gameToUpper({game})} - Available Tilesets</title>
	            <meta name="description" content={"Choose your favorite tileset and play " + gameToUpper({game}) + " - " + t(game + "Description")} />
	            <link rel="canonical" href={"https://www.unlimited-tiles.com/" + detectedLanguage + "/" + game + "/tilesets"} />
	            <meta property="og:url" content={"https://www.unlimited-tiles.com/" + detectedLanguage + "/" + game + "/tilesets"} />
	            <meta property="og:image" content="https://www.unlimited-tiles.com/banner2.png" />
	            <meta property="og:title" content={"Unlimited Tiles - " + gameToUpper({game}) + "- Available Tilesets"} />
	        </Helmet>
			<Snow/>

	        <div className="mainPart">
		        <Container>
		            <NewAd type="tilesetsTop"/>

		        </Container>
				<br/>
		        <Container id="gamesContainer">
					<h1>All games</h1>
					<Row>
					{ allgames.map(g => ( <Icon game={g} current={game} /> )) }
					</Row>

				</Container>



                <Container id="bigContainter">
                    <br/>
					<h1>{bigName(game)}</h1>
					{ game == "calendar"  && tilesetsAvailable.length > 0
						? <center><h5>Open all 24 calendar windows to unlock special levels starting December 25th!</h5></center>
						: <center><h4>We've grouped the levels to make collecting stars easier! <br/>
                                                    					Don't worry, no levels are missing!</h4></center>
					}
					<h2>
		                <><img className="biggerStar" src={"/images/tilesets/lines/star.png"}/>

		                    {
							game == "multishuzzle"
		                    ? countStars(game) + " / " + (27*tilesetsAvailable.length)
		                    : countStars(game) + " / " + (3*tilesetsAvailable.length)
		                    }
		                </>

					<div className="toggle-container" onClick={toggleOption}>

						<div className={selectedOption == 'A' ? 'toggle-switch  toggle-chosen' : 'toggle-switch  '}>
							{selectedOption == 'A' ? <>✓ </> : <></> }
							Categories </div>
						<div className={selectedOption == 'B' ? 'toggle-switch  toggle-chosen' : ' toggle-switch '}>
							{selectedOption == 'B' ? <>✓ </> : <></> }
							All levels </div>
		            </div>

		            </h2>
		            {
		                linkCopied
		                ? <center><h4>Your results copied to the clipboard :) </h4></center>
		                : <></>
		            }

		            {
		                selectedOption == "B"
		                ?  <Row>
		                    {tilesets.map(tileset => (
		                         <Tileset game={game} tileset={tileset["name"]} stars={getStarsTileset({tileset, game})}
		                        key={tileset["name"]} date={tileset["date"]} />

		                    ))}
		                    </Row>


						: shown < 0
						?   <Row> {
		                    months.map((month, id) =>
		                        month["availableStars"] > 0
		                        ? <div className="tilesetsMonth tilesetsMonthClosed"  onClick={() => toggleVisibility(id)}>
								{
		                            month["newLevels"] == 1
		                            ? <span className="tilesetsMonthNewLevel">NEW LEVEL EVERY DAY!<br/></span>
		                            : <></>
		                        }

								<img className="tilesetsMonthImage" alt={game + " cover " + month["month"]} variant="top"
                                        src={
                                        ["shuzzle","epicshuzzle","multishuzzle", "pixit"].includes(game)
                                        ? "/images/games/" + game + "/" + month["cover"] + "x.png"
                                        : ["infinite", "timed", "pairs", "unpaired"].includes(game)
                                            ? "/images/games/tiles/" + month["cover"] + ".png"
                                            : "/images/games/" + game + "/" + month["cover"] + ".png"
                                        }  />

		                        <div className="tilesetsMonthHeader">


		                            <span className="tilesetsMonthTileset"> { monthToTitle(month["month"], game) } </span>
		                            <br/>
									<img className="monthStar" src={"/images/tilesets/lines/star.png"}/>
									&nbsp;
									<ProgressBar className="tilesetsMonthProgress" now={ 100* month["stars"] / month["availableStars"]}  />
		                            { month["stars"]} / {month["availableStars"]}

		                        </div>
		                        </div>
		                        : <></>
		                    )}
		                    </Row>
		                : <Row><div className="tilesetsMonth">

							<div className="tilesetsMonthHeaderBig"  onClick={() => toggleVisibility(-1)}>

									<div className="tilesetsMonthTriangle">◂</div>
									<div className="tilesetsMonthStats">

									<span className="tilesetsMonthName"> {monthToTitle(months[shown]["month"], game) } </span>
                                    <br/>

									<img className="monthStar" src={"/images/tilesets/lines/star.png"}/>
									&nbsp;&nbsp;
									<ProgressBar className="tilesetsMonthProgress"
										now={ 100* months[shown]["stars"] / months[shown]["availableStars"]}  />
		                            { months[shown]["stars"]} / {months[shown]["availableStars"]}
		                            </div>
		                        </div>
							<br/><br/>
		                    <Row>
		                    {months[shown]["tilesets"].map(tileset => (
		                         <Tileset game={game} tileset={tileset["name"]} stars={getStarsTileset({tileset, game})}
		                        key={tileset["name"]} date={tileset["date"]} />

		                    ))}
		                    </Row>

		                </div></Row>
		            }

		            <NewAd type="tilesetsBottom"/>
		        </Container>
				<br/>
	        </div>


			<Comments/>

			<Moregames/>
        </div>
      );

    const lastTilesetsHtml = (
        <>
        <br/>
        <h2>Choose next level</h2>
        <Row className="next-levels">
            <img className="arrow-small" src="/images/left.png" onClick={() => prevLevels()}></img>
			{tilesetsNotFinished.slice(firstLevel, firstLevel+numberOfLevelsInRow).map(tileset => (
		        <Tileset game={game} tileset={tileset["name"]} stars={getStarsTileset({tileset, game})}
		                        key={tileset["name"]} date={tileset["date"]} />

		    ))}
			<img className="arrow-small" src="/images/right.png" onClick={() => nextLevels()}></img>
		</Row>

		</>

    );

	if(last) {
		if(tilesetsNotFinished.length == 0) {
			return (<></>);
		} else {
			return lastTilesetsHtml;
		}
	} else {
        return tilesetHtml;
    }
}
export default Tilesets;

