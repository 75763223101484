import results from '../static/json/results.json';

import levels_calendar from '../static/json/levels/general_calendar.json';
import levels_fourlayers from '../static/json/levels/general_fourlayers.json';
import levels_lines from '../static/json/levels/general_lines.json';
import levels_neighbours from '../static/json/levels/general_neighbours.json';
import levels_pixit from '../static/json/levels/general_pixit.json';
import levels_same from '../static/json/levels/general_same.json';
import levels_segments from '../static/json/levels/general_segments.json';
import levels_shuzzle from '../static/json/levels/general_shuzzle.json';
import levels_epicshuzzle from '../static/json/levels/general_epicshuzzle.json';
import levels_multishuzzle from '../static/json/levels/general_multishuzzle.json';
import levels_sparkit from '../static/json/levels/general_sparkit.json';
import levels_swapoku from '../static/json/levels/general_swapoku.json';
import levels_waiting from '../static/json/levels/general_waiting.json';


const levels = {
	"calendar": levels_calendar,
    "fourlayers": levels_fourlayers,
    "lines": levels_lines,
    "neighbours": levels_neighbours,
    "pixit": levels_pixit,
    "same": levels_same,
    "segments": levels_segments,
    "shuzzle": levels_shuzzle,
    "sparkit": levels_sparkit,
    "swapoku": levels_swapoku,
    "waiting": levels_waiting,
    "epicshuzzle": levels_epicshuzzle,
    "multishuzzle": levels_multishuzzle
}

export function getLevels(game) {
	var ts, tilesets = [];
	const tilesgames = ["tiles", "tilesbig", "pairs", "infinite", "timed", "unpaired"];
	var listName = tilesgames.includes(game) ? "waiting" : game;


	return levels[listName]["levels"];
}



export function getAllGames() {
	return ["calendar", "pixit", "sparkit", "shuzzle", "epicshuzzle", "segments",  "swapoku", "neighbours", "pairs",
	 "same", "timed", "infinite", "lines", "unpaired", "tiles"];
}