import results from '../static/json/results.json';
import levels_lines from '../static/json/levels_lines.json';
import levels_shuzzle from '../static/json/levels_shuzzle.json';
import levels_epicshuzzle from '../static/json/levels_epicshuzzle.json';
import levels_multishuzzle from '../static/json/levels_multishuzzle.json';
import levels_pixit from '../static/json/levels_pixit.json';
import levels_calendar from '../static/json/levels_calendar.json';
import { getLevels } from './levels.js';



function getAllLevels({allgames}) {
	var levels = {};
	for(var i = 0; i < allgames.length; i++) {
		var game = allgames[i];
		levels[game] = getLevels(game);
	}
	return levels;
}

function getStarsRulesForMulti(tileset) {

	var splits = tileset.split("/");
	if(splits.length == 1) {
		return [0,0];
	}

	var id = Number(splits[0]) - 1;
	var part = Number(splits[1]);
	var x = Math.floor(part / 10) - 1;
    var y = (part % 10) - 1;
    var parts = levels_multishuzzle["parts"][id]["parts"][x][y];
    var difficulty = "easy";
    if(parts == 5) {difficulty = "medium";}
    if(parts == 6) {difficulty = "hard";}
    var three_stars = results["multishuzzle"][difficulty]["three"];
    var two_stars = results["multishuzzle"][difficulty]["two"];
    return [three_stars, two_stars];
}


export function getPointsNeeded(tileset, game) {

	const fourLayersSets = getLevels("fourlayers");
	var three_stars;

	if(game == "tiles" && fourLayersSets.includes(tileset)) {game = "fourlayers";}
	if(game == "lines") {
		three_stars = levels_lines[tileset]["best"];
		return "To earn 3 stars, you can make up to " + three_stars + " moves."
	} else if(game == "shuzzle") {
		var difficulty = "easy";
		if(levels_shuzzle["extreme"].includes(Number(tileset))) {difficulty = "extreme";}
		if(levels_shuzzle["hard"].includes(Number(tileset))) {difficulty = "hard";}
        if(levels_shuzzle["medium"].includes(Number(tileset))) {difficulty = "medium";}
        three_stars = results[game][difficulty]["three"];
		return "To earn 3 stars, you can make up to " + three_stars + " moves."
	} else if(game == "epicshuzzle") {
		var difficulty = "ultra";
		if(levels_epicshuzzle["extreme"].includes(Number(tileset))) {difficulty = "extreme";}
		if(levels_epicshuzzle["ultimate"].includes(Number(tileset))) {difficulty = "ultimate";}
        three_stars = results[game][difficulty]["three"];
		return "To earn 3 stars, you can make up to " + three_stars + " moves."
	} else if(game == "pixit") {
		var difficulty = "easy";
		if(levels_pixit["hard"].includes(Number(tileset))) {difficulty = "hard";}
        if(levels_pixit["medium"].includes(Number(tileset))) {difficulty = "medium";}
        three_stars = results[game][difficulty]["three"];
		return "To earn 3 stars, you can make up to " + three_stars + " moves."
	} else if(game == "calendar") {
		var difficulty = "easy";
		if(levels_calendar["hard"].includes(Number(tileset))) {difficulty = "hard";}
        three_stars = results[game][difficulty]["three"];
		return "To earn 3 stars, you can make up to " + three_stars + " moves."
	} else if(game == "multishuzzle") {
        three_stars = getStarsRulesForMulti(tileset)[0];
	} else {
		if(results[game]["all"]) {
			three_stars = results[game]["three"];
        } else {
            three_stars = results[game][tileset]["three"];
        }
	}
	var rule = results[game]["rule"];
	if(["tiles", "fourlayers", "pairs"].includes(game)) {
		return "To earn 3 stars, your score has to be " + three_stars + "."
	}
	if(["swapoku", "same", "segments", "calendar"].includes(game)) {
		return "To earn 3 stars, you can make up to " + three_stars + " moves."
	}
	if(rule == "bigger") {
		return "To earn 3 stars, your score has to be at least " + three_stars + "."
	} else {
		return "To earn 3 stars, your score has to be at most " + three_stars + "."
	}
}



export function getStarsRules(game,tileset) {
	var three_stars, two_stars;
	const fourLayersSets = getLevels("fourlayers");

	if(game == "tiles" && fourLayersSets.includes(tileset)) {game = "fourlayers";}
	if(game == "lines") {
		three_stars = levels_lines[tileset]["best"];
		two_stars = three_stars + 3;
	} else if(game == "shuzzle") {
		var difficulty = "easy";
		if(levels_shuzzle["extreme"].includes(Number(tileset))) {difficulty = "extreme";}
		if(levels_shuzzle["hard"].includes(Number(tileset))) {difficulty = "hard";}
        if(levels_shuzzle["medium"].includes(Number(tileset))) {difficulty = "medium";}
        three_stars = results[game][difficulty]["three"];
        two_stars = results[game][difficulty]["two"];
	}  else if(game == "epicshuzzle") {
		var difficulty = "ultra";
		if(levels_epicshuzzle["extreme"].includes(Number(tileset))) {difficulty = "extreme";}
		if(levels_epicshuzzle["ultimate"].includes(Number(tileset))) {difficulty = "ultimate";}
        three_stars = results[game][difficulty]["three"];
        two_stars = results[game][difficulty]["two"];
	} else if(game == "pixit") {
		var difficulty = "easy";
		if(levels_pixit["hard"].includes(Number(tileset))) {difficulty = "hard";}
        if(levels_pixit["medium"].includes(Number(tileset))) {difficulty = "medium";}
        three_stars = results[game][difficulty]["three"];
        two_stars = results[game][difficulty]["two"];
	} else if(game == "calendar") {
		var difficulty = "easy";
		if(levels_calendar["hard"].includes(Number(tileset))) {difficulty = "hard";}
        three_stars = results[game][difficulty]["three"];
        two_stars = results[game][difficulty]["two"];
	} else if(game == "multishuzzle") {
        var stars_both = getStarsRulesForMulti(tileset);
        three_stars = stars_both[0];
        two_stars = stars_both[1];
	} else {
		if(results[game]["all"]) {
			three_stars = results[game]["three"];
	        two_stars = results[game]["two"];
        } else {
            three_stars = results[game][tileset]["three"];
            two_stars = results[game][tileset]["two"];
        }
	}
	return [three_stars, two_stars];
}

export function getStarsAmount(tileset, game, points) {
	if(points == undefined || points == null) {
		return 0;
	}

	var rules = getStarsRules(game, tileset);
	var three_stars = rules[0];
	var two_stars = rules[1];
	var rule = results[game]["rule"];

	if(rule == "bigger") {
		if(points >= three_stars) {return 3;}
		if(points >= two_stars) {return 2;}
		return 1;
	} else {
		if(points <= three_stars) {return 3;}
		if(points <= two_stars) {return 2;}
		return 1;
	}
}


function getStarsNormal(tileset, game) {
	var best = localStorage.getItem(game + "-" + tileset + "-best");
    var starsAmount = getStarsAmount(tileset, game, best);
    localStorage.setItem(game + "-" + tileset + "-stars", starsAmount);
    return starsAmount;
}

function getStarsMulti(tileset) {
	var all = 0;
	for(var i = 1; i <= 3; i++) {
		for (var j = 1; j <= 3; j++) {
			var id = 10*i+j;
			all += getStarsNormal(tileset + "/" + id, "multishuzzle");
		}
	}
	return all;
}

export function getStars(tileset, game) {
	if(game == "multishuzzle") {
		return getStarsMulti(tileset);
	} else {
		return getStarsNormal(tileset, game);
	}
}

function countAllStars({allgames, levels}) {
	for(var i = 0; i < allgames.length; i++) {
		var game = allgames[i];
		var gameLevels = levels[game];
		for(var j = 0; j < gameLevels.length; j++) {
			var tileset = gameLevels[j];
			getStars(tileset, game);
		}
	}
}


export function countStars() {

	var allgames = ["tiles", "pairs", "infinite", "timed", "unpaired", "same", "lines"];
	var levels = getAllLevels({allgames});

	countAllStars({allgames, levels});
}