import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import '../../static/css/tilesets.css';



function bigName(gameName) {
		const names = {
        	"calendar": "ADVENT CALENDAR",
        	"lines": "COLOR LINES",
        	"same": "SAME GAME",
        	"epicshuzzle": "EPIC SHUZZLE"
        };
        if(gameName in names) {
        	return names[gameName];
        } else {
			return gameName.toUpperCase();
		}
}

function Minigame({game, type}) {


	const { t } = useTranslation();

    const todayDate = new Date();
    const shuzzleDate = new Date("2025-01-01");
    shuzzleDate.setHours(shuzzleDate.getHours() - 1);
	if(game == "epicshuzzle" && todayDate < shuzzleDate ) {
		return (<></>);
	}

	var detectedLanguage = window.location.pathname.split('/')[1];
    if(detectedLanguage === "" || detectedLanguage.length > 2) {
    	detectedLanguage = "en";
    }


    function textToUpper({game}) {
        if(game === "same") { return "SAME GAME";}
        if(game === "calendar") { return "ADVENT CALENDAR";}
        return game.toUpperCase();
	}

	function getUrl(detectedLanguage, game) {

		//return "/" + detectedLanguage + "/" + game + "/tilesets";
		if(game == "tiles") {
			return "/";
		} else {
			return "/" + detectedLanguage + "/" + game;
		}

	}

	function getAlt({game}) {
		return game + " game";
	}
	const shuzzleStyle = (game == "shuzzle" || game == "epicshuzzle") ? "tilesetPromoted"  : "";


    return (
        <Col className='tilesetContainer' >
            <Card className={'tilesetCard tilesetCardSmaller ' + shuzzleStyle} >
                <a href={getUrl(detectedLanguage, game)}>
                 <Card.Img alt={getAlt({game})} variant="top" src={"/images/games/" + game + "/default.webp"}  />

                <Card.Body>
                    <Card.Title>{bigName(game)}</Card.Title>
                    <Button href={getUrl(detectedLanguage, game)} className="small-btn" variant="primary" >PLAY</Button>


                </Card.Body>
                </a>
            </Card>
        </Col>
    );
}

function Reddit() {

    return (
        <Col className='tilesetContainer'>
            <Card className='tilesetCard tilesetCardSmaller' >
                <a href="https://www.reddit.com/r/UnlimitedTiles/">
                 <Card.Img alt="reddit unlimited tiles" variant="top" src={"/images/reddit.webp"}  />

                <Card.Body>
                    <Card.Title>REDDIT</Card.Title>
                    <Button href="https://www.reddit.com/r/UnlimitedTiles/" className="small-btn" variant="primary" >JOIN US</Button>


                </Card.Body>
                </a>
            </Card>
        </Col>
    );
}



const Minigames = ({random = false, game = "tiles"}) => {
	const { t } = useTranslation();
	var games = [ "epicshuzzle", "shuzzle", "calendar", "pixit", "sparkit",  "segments",  "swapoku", "neighbours", "pairs",  "same", "timed", "infinite", "lines", "unpaired", "tiles"];
    var promoted = ["pixit", "sparkit", "shuzzle", "swapoku", "same"]; //"epicshuzzle"
    var reddit = ["reddit"];

	if(random) {

		const shuffled = promoted.filter(function(e) { return e !== game}).sort(() => 0.5 - Math.random());
        games = shuffled.slice(0, 2);
        if(game != "shuzzle" && games[1] != "shuzzle") {
            games[0] = "shuzzle";
        }

	}

	const gamesHtml =  (

		<Container>
	        {
	            random
	            ? <><br/><h2>You can also play one of our other games</h2>
	            </>
	            :  <><h2>{ t("otherGames") }</h2>
	            <p><b>Unlimited Tiles</b> is more than just Tiles. It’s a collection of tile-based games we've created. Some of them require logical thinking, while others focus more on finding similarities between tiles, but all of them are engaging and relaxing. Which one is your favorite?</p>
	            <p className="intro"><a href="/blog/our-games"><b>Click here </b> to read more about our tile games. </a></p>
	            </>
	        }
	        {
	            <Row className="allgames">
	                {games.map(game => ( <Minigame game={game} key={game} type="in"/>))}
	                {
	                    random
	                    ? <></>
	                    : <Reddit/>
	                }

	            </Row>
	        }
	    </Container>
    );

    return gamesHtml;
}
export default Minigames;
