import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Languages from './Languages'

import '../static/css/header.css';

import { useTranslation } from 'react-i18next';
import { getAllGames } from '../library/levels.js';


import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZGRQLF886B');

function Header() {
	const { t } = useTranslation();
	var detectedLanguage = window.location.pathname.split('/')[1];
	if(detectedLanguage == "en" || detectedLanguage.length > 2) {
		detectedLanguage = "";
	}
	var game = "tiles";
	var params = window.location.pathname.split('/');
	if(params.length >= 3) {
		var detectedGame = params[2];
		if(detectedGame.length >= 3) {
			game = detectedGame;
		}
		var games = getAllGames();
		if(!games.includes(game)) {
			game = "shuzzle";
		}
	}

	function play() {
		ReactGA.event({
	        category: 'Game',
	        action: 'FromHeaderToEpic'
	    });
	    window.location.href = "/en/epicshuzzle"
	}


	return (
		<>
        <Navbar expand="lg" className="bg-body-tertiary tilesNavbar">
            <Container>

                <Navbar.Brand href={"/" + detectedLanguage}>
                    <img className="d-inline-block align-top logo" src="/logo.webp" alt="Tiles"/>
                </Navbar.Brand>

                { /*<Languages/>*/ }

				<a href="/badges">
                    <img className="d-inline-block align-right navbar-icon" src="/badges.webp" alt="Badges"/>
                </a>
                <a href={"/en/" + game + "/tilesets"}>
                    <img className="d-inline-block align-right navbar-icon" src="/tilesets.webp" alt="Tilesets"/>
                </a>
                { /* <a href="https://www.reddit.com/r/UnlimitedTiles/">
                    <img className="d-inline-block align-right navbar-icon" src="/reddit.webp" alt="Reddit"/>
                </a> */}

                <a href="/games">
                    <img className="d-inline-block align-right navbar-icon" src="/games.webp" alt="Games"/>
                </a>
                {/*<a href="/contact">
                    <img className="d-inline-block align-right navbar-icon" src="/contact.webp" alt="Contact"/>
                </a>*/ }
            </Container>
        </Navbar>

        <Navbar  expand="lg" className="bg-body-tertiary tilesNews">

	        <Container onClick={() => play()}>
				<span>Play our new game - Epic Shuzzle!</span>
	        </Container>

        </Navbar>



        </>
    );
}

export default Header;